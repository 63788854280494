import { Icon, Button } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import { updateMe } from "ReduxActions/authActions";

import { Container, CustomButton } from "SharedComponents";
import { InputText } from "../components/Input";
import ResetPassword from "../components/ResetPassword";
import ProfileImage from "../components/ProfileImage";

import "./ProfileContext.scss";

function ProfileContext(props) {
  const [ showPwd, setShowPwd ] = useState(false);

  const onSubmit = (values) => {
    const newUser = {
      fullname: values.invProfile.fullname,
      image: values.invProfile.image,
      email: values.email,
      phone: values.invProfile.phone,
    };
    props.updateMe(newUser);
  };

  return (
    <div className="ProfileContext">
      <div className="m-3">
        <Icon type="setting" />
        <span className="ml-2">My Profile</span>
      </div>
      <form onSubmit={props.handleSubmit(onSubmit)}>
        <div className="row mt-5 justify-content-md-center">
          <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
            <Container padding={32}>
              <div className="row flex-center">
                <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                  <Field
                    name="invProfile.image"
                    component={ProfileImage}
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <Field
                        name="invProfile.fullname"
                        component={InputText}
                        placeholder="Full name"
                        label="Full name"
                        required={true}
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <Field
                        name="email"
                        component={InputText}
                        placeholder="Email"
                        label="Email"
                        required={true}
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <Field
                        name="invProfile.phone"
                        component={InputText}
                        placeholder="Phone number"
                        label="Phone number"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <Field
                    name="company.name"
                    component={InputText}
                    placeholder="Company"
                    label="Company"
                    disabled={true}
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    name="created_at"
                    component={InputText}
                    placeholder="Member since"
                    label="Member since"
                    disabled={true}
                    format={value => moment(value * 1000).format("D MMMM YYYY")}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12">
                  <CustomButton
                    label="CHANGE PASSWORD"
                    block={true}
                    size="large"
                    onClick={() => setShowPwd(true)}
                  />
                </div>
              </div>
              <div className="row mt-4 justify-content-md-center">
                <div className="col-md-6 flex-center">
                  <Button.Group>
                    <CustomButton
                      label="Reset"
                      icon="close"
                      size="large"
                      onClick={props.reset}
                    />
                    <CustomButton
                      label="Save"
                      icon="check"
                      type="primary"
                      className="primary"
                      size="large"
                      block={false}
                      htmlType="submit"
                    />
                  </Button.Group>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </form>
      <ResetPassword
        visible={showPwd}
        handleCancel={() => setShowPwd(false)}
      />
    </div>
  );
}

function mapStateToProps({ auth }) {
  return {
    initialValues: auth.user,
  };
};

ProfileContext = reduxForm({
  form: "profile-form",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(ProfileContext);

export default connect(mapStateToProps, { updateMe })(ProfileContext);
