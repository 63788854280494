import api from "./api";
import { Notifier } from "SharedComponents";

export const INVESTEMENTS_LOADING = "investments:loading:all";
export const INVESTEMENTS_LOADED = "investments:loaded:all";
export const INVESTEMENTS_DISTRIBUTION_LOADED = "investments:distribution:loaded:all";
export const EXITED_INVESTEMENTS_LOADED = "exited:investments:distribution:loaded:all";
export const EXITED_INVESTEMENTS_LOADING = "exited:investments:loading";
export const EXITED_INVESTEMENTS_RESET = "reset:exited:investment";

export function loadInvestments() {
  return dispatch => {
    dispatch({ type: INVESTEMENTS_LOADING, payload: true });
    api.get("/deals/all")
    .then(({ data }) => {
      dispatch({ type: INVESTEMENTS_LOADED, payload: data });
    })
    .catch(({response}) => {
      dispatch({ type: INVESTEMENTS_LOADING, payload: false });
      Notifier({
        type: "error",
        title: "Could not load your investments",
        message: "Please try again",
      });
    });
  }
}
export function resetExited()
{
  return dispatch => {
    dispatch({ type: EXITED_INVESTEMENTS_RESET});
  }
}
export function loadExited() {
  return dispatch => {
    dispatch({ type: EXITED_INVESTEMENTS_LOADING, payload: true });
    api.get("/deals/exited")
      .then(({ data }) => {
        dispatch({ type: EXITED_INVESTEMENTS_LOADED, payload: data });
      })
      .catch(({response}) => {
        dispatch({ type: EXITED_INVESTEMENTS_LOADING, payload: false });
        Notifier({
          type: "error",
          title: "Could not load your investments",
          message: "Please try again",
        });
      });
  }
}
export function loadInvestmentsDistribution() {
  return dispatch => {
    dispatch({ type: INVESTEMENTS_LOADING, payload: true });
    api.get("/investor-dists/all")
    .then(({ data }) => {
      dispatch({ type: INVESTEMENTS_DISTRIBUTION_LOADED, payload: data });
    })
    .catch(({response}) => {
      dispatch({ type: INVESTEMENTS_LOADING, payload: false });
      Notifier({
        type: "error",
        title: "Could not load your investments",
        message: "Please try again",
      });
    });
  }
}
