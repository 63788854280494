import React, { Component } from "react";
import { Result } from 'antd';

// import { NumberCard } from "SharedComponents";
// import IndicatorBoard from "../IndicatorBoard";
// import ChartKPIs from "../ChartKPIs";
// import TableKPIs from "../TableKPIs";

import "./DashboardContext.scss";

export default class DashboardContext extends Component {
  render() {
    return (
      <div className="DashboardContext">
        {/* <IndicatorBoard />
        <div className="row mt-5">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8"><ChartKPIs /></div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4"><TableKPIs /></div>
        </div> */}
        <Result
          status="info"
          title="Dashboard is coming soon..."
          subTitle="STAY TUNED"
        />
      </div>
    );
  }
}
