import api from "./api";
import { Notifier } from "SharedComponents";

export const DEAL_LOADING = "deal:loading";
export const DEAL_LOADED = "deal:loaded";
export const DEAL_NOTES_LOADED = "deal:notes:loaded";
export const DEAL_FILES_LOADED = "deal:files:loaded";
export const PATH_FOLDERS_UPDATED = "deal:files:path:updated";
export const LOADING_ITEMS = "deal:items:loading";
export const EMPTY_ITEMS = "deal:items:empty";
export const DEAL_DISTRIBUTION_LOADED = "deal:distribution:loaded";

export function emptyItems(item) {
  return dispatch => dispatch({ type: EMPTY_ITEMS, payload: item });
}

export function loadDeal(deal_id) {
  return dispatch => {
    dispatch({ type: DEAL_LOADING, payload: true });
    api.get(`/deals/${deal_id}`)
    .then(({ data }) => {
      dispatch({ type: DEAL_LOADED, payload: data });
    })
    .catch(({response}) => {
      dispatch({ type: DEAL_LOADING, payload: false });
      const message =  response && response.data ? response.data.message : "An error has been detected";
      Notifier({
        type: "error",
        title: message,
        message: "",
      });
    });
  }
}

export function loadNotes(deal_id, page, size) {
  return dispatch => {
    dispatch({ type: LOADING_ITEMS, payload: true });
    api.get(`/notes?filter[deal_id]=${deal_id}&page=${page}&per-page=${size}&sort=-created_at`)
      .then(({ data }) => {
        dispatch({ type: DEAL_NOTES_LOADED, payload: data.results, meta: data._meta });
      })
      .catch(({response}) => {
        dispatch({ type: LOADING_ITEMS, payload: false });
        Notifier({
          type: "error",
          title: "Could not load your notes",
          message: "Please try again",
        });
      });
  }
}

export function loadFiles(deal_id, folder) {
  return dispatch => {
    dispatch({ type: LOADING_ITEMS, payload: true });
    dispatch({ type: PATH_FOLDERS_UPDATED, payload: folder });
    api.get(`/files?filter[deal_id]=${deal_id}&filter[parent_id]=${folder.path}`)
    .then(({ data }) => {
      dispatch({ type: DEAL_FILES_LOADED, payload: data });
    })
    .catch(({response}) => {
      dispatch({ type: LOADING_ITEMS, payload: false });
      Notifier({
        type: "error",
        title: "Could not load your files",
        message: "Please try again",
      });
    });
  }
}

export function loadDistribution(deal_id, page, size) {
  return dispatch => {
    dispatch({ type: LOADING_ITEMS, payload: true });
    api
      .get(`/investor-dists/${deal_id}?page=${page}&per-page=${size}&sort=-created_at`)
      .then(({data}) => {
        dispatch({ type: DEAL_DISTRIBUTION_LOADED, payload: data.results, meta: data._meta });
      })
      .catch((err) => {
        dispatch({ type: LOADING_ITEMS, payload: false });
        Notifier({
          type: "error",
          title: "Something went wrong",
          message: "Please try again",
        });
      })
  }
}