import React, { useState } from "react";
import { Typography, Icon } from 'antd';
import {
  PieChart,
  Pie,
  Cell,
  Sector,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";

import { EmptyData } from "SharedComponents";
import emptystate from "Assets/images/emptystate.svg";

import "./CustomPieChart.scss";

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export function SimplePieChart(props) {
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (data, index) => {
    setActiveIndex(index);
  };
  function showLabel(e)
  {
    if(props.mode == "pdf")
    {
      return `${e.value} ( ${(e.percent * 100).toFixed(2)}% )`
    }else {
      return undefined
    }
  }
  return (
    <div className="CustomPieChart">
      <Typography.Text strong>{props.title}</Typography.Text>
      <div style={{ width: '100%', height: 400 }}>
        {
          props.loading ? (
            <div className="spinner-class">
              <Icon type="loading" style={{color: "#1890ff", fontSize: "32px"}} />
            </div>
          ) : (
            props.data.length ? (
              <ResponsiveContainer>
                <PieChart>
                  {props.mode == "pdf"?
                    <Pie
                      activeIndex={null}
                      activeShape={null}
                      dataKey="value"
                      startAngle={0}
                      endAngle={360}
                      data={props.data}
                      outerRadius={80}
                      fill="#8884d8"
                      label={e =>`${e.value} ( ${(e.percent * 100).toFixed(2)}% )` }
                      onMouseEnter={null}
                    >
                      {props.data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={props.colors[index % props.colors.length]}
                        />
                      ))}
                    </Pie>:
                    <Pie
                      activeIndex={activeIndex}
                      activeShape={renderActiveShape}
                      dataKey="value"
                      startAngle={0}
                      endAngle={360}
                      data={props.data}
                      outerRadius={80}
                      fill="#8884d8"
                      onMouseEnter={onPieEnter}
                    >
                      {props.data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={props.colors[index % props.colors.length]}
                        />
                      ))}
                    </Pie>
                  }

                  {/* <Tooltip /> */}
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            ) : (
              <div className="pt-5">
                <EmptyData image={`${emptystate}`} imageStyle={{ height: "200"}} description="No data found" />
              </div>
            )
          )
        }
      </div>
    </div>
  );
}

const renderActiveShape = props => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
    name,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";
let arrayName=name.split(' ')
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      {arrayName.map((item,i)=><text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={i*19}
        textAnchor={textAnchor}
        fill="#333"
        key={item}
      >{item}</text>) }
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={arrayName.length *19}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`${(percent * 100).toFixed(2)}%`}
        </text>
    </g>
  );
};

export function CurvedPieChart(props) {
  return (
    <div className="CustomPieChart">
      <Typography.Text strong>{props.title}</Typography.Text>
      <div style={{ width: '100%', height: 400  }}>
      {
          props.loading ? (
            <div className="spinner-class">
              <Icon type="loading" style={{color: "#1890ff", fontSize: "32px"}} />
            </div>
          ) : (
            props.data.length ? (
              <ResponsiveContainer>
                <PieChart
                  dataKey="value"
                  startAngle={0}
                  endAngle={360}
                  data={props.data}
                >
                  <Pie
                    data={props.data}
                    dataKey="value"
                    label={e => `${(e.percent * 100).toFixed(0)}%`}

                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={5}
                  >
                    {props.data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={props.colors[index % props.colors.length]}
                      />
                    ))}
                  </Pie>
                  <Legend />
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            ) : (
              <div className="pt-5">
                <EmptyData image={`${emptystate}`} imageStyle={{ height: "200"}} description="No data found" />
              </div>
            )
          )
        }
      </div>
    </div>
  );
}
