import React from "react";
import Icon from "antd/lib/icon";
import Spin from "antd/lib/spin";

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

export const Spinner = props => {
  if (props.isLoading) return <Spin indicator={antIcon} />;
  else return null;
};

export const OverlaySpinner = props => (
  <Spin tip={props.tip} spinning={props.isLoading}>
    {props.children}
  </Spin>
);
