import { Icon } from "antd";
import moment from "moment";
import React from "react";
import { CustomButton } from "SharedComponents";
import { formatDecimalNumber, numberRoundFormatter } from "Utils/formatNumber";
import buildingImg from "Assets/images/commercial-icon.png";
import { get } from "lodash";

import "./AssetCard.scss";

export const AssetCard = props => (
  <div className="AssetCard">
    <div className="section">
      <div className="info-block text-ellipsis">
        {props.name}
      </div>
    </div>
    <div className="">
      <img className="goal-img" src={props.image || buildingImg} />
    </div>
    <div className="section">
      <div className="requirement flex-start-wrap">
        <Icon type="global" />
        <span className="ml-2 requirement-title text-ellipsis">{props.countryName ? props.countryName.name : "--" }</span>
      </div>
      <div className="requirement flex-between">
        <div className="requirement-title">Current Yield (Last Distribution)</div>
        <div className="requirement-value">{props.return_rate ? `${formatDecimalNumber(props.return_rate)} %` : "--"}</div>
      </div>
      <div className="requirement flex-between">
        <div className="requirement-title text-ellipsis"><span className="info-investments" onClick={props.showInvestments}>My investment <Icon type="info-circle" /></span></div>
        <div className="requirement-value text-ellipsis">{numberRoundFormatter(props.investment_amount, get(props.currencyName, 'code', 'USD'))}</div>
      </div>
      <div className="requirement flex-between">
        <div className="requirement-title text-ellipsis">Distribution as on date</div>
        <div className="requirement-value text-ellipsis">{numberRoundFormatter(props.return_per_date, get(props.currencyName, 'code', 'USD'))}</div>
      </div>
      <div className="requirement flex-between">
        <div className="requirement-title text-ellipsis">SPV Name</div>
        <div className="requirement-value text-ellipsis">{props.svp_name}</div>
      </div>
      {props.showInvestmentDate && <div className="requirement flex-between">
        <div className="requirement-title text-ellipsis">Date of investment</div>
        <div className="requirement-value text-ellipsis">{moment(props.date_of_investment * 1000).format("D MMMM YYYY")}</div>
      </div>}
      {props.showFrequency && <div className="requirement flex-between">
        <div className="requirement-title text-ellipsis">Frequency of payment</div>
        <div className="requirement-value text-ellipsis">{props.frequency || "--"}</div>
      </div>}
    </div>
    {props.showRedirect && <div>
      <hr className="light-hr" />
      <div className="custom-link">
        <CustomButton type="link" icon="arrow-right" label="See details" block={true} onClick={() => props.openDeal(props.deal_id)} />
      </div>
    </div>}
  </div>
);
