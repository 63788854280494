import { Table, Typography } from "antd";
import moment from "moment";
import React from "react";
import { formatDecimalNumber, numberRoundFormatter } from "Utils/formatNumber";
import { Container } from "SharedComponents";

export default function DistributionTable(props) {

  const handleTableChange = (pagination, filters, sorter) => {
    props.loadDistribution(pagination.current, pagination.pageSize);
  };

  const columns = [
    {
      title: "Period",
      dataIndex: "rate_date",
      render: (text, it) => <Typography.Text strong>{moment(text).isValid() ? moment(text).format("D MMMM YYYY") : text}</Typography.Text>,
    },
    {
      title: "Current Yield (Last Distribution)",
      dataIndex: "rate",
      render: (text, it) => <div>{formatDecimalNumber(text)} %</div>,
    },
    {
      title: `${props.frequency} Distributions`,
      dataIndex: "return_rate",
      render: (text, it) => <div>{numberRoundFormatter(text, it.currency)}</div>,
    },
    {
      title: "Cumulative Distribution",
      dataIndex: "return_rate_total",
      render: (text, it) => <span>{numberRoundFormatter(text, it.currency)}</span>,
    },
  ];

  return (
    <div className="m-2">
      <Container padding={0}>
        <Table
          pagination={false}
          showHeader={true}
          columns={columns}
          rowKey={(record, key) => key}
          dataSource={props.distribution}
          pagination={{
            position: "bottom",
            hideOnSinglePage: true,
            pageSize: props.meta.perPage,
            total: props.meta.totalCount,
            itemRender: itemRender,
          }}
          loading={props.loading}
          onChange={handleTableChange}
        />
      </Container>
    </div>
  );
};

function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return <a>Previous</a>;
  }
  if (type === "next") {
    return <a>Next</a>;
  }
  return originalElement;
}
