import React, { useState, useEffect } from "react";
import { Typography } from 'antd';
import { CustomButton } from "SharedComponents";
import pentugramLogo from "Assets/images/Gold@3x.svg";
import arzanWealthLogo from "Assets/images/arzanWealth.png";

export default function ResetPassword(props) {
  const [ email, setEmail ] = useState("");
  const [logo, setLogo] = useState({ url: pentugramLogo, h: 78, w: 78});

  useEffect(() => {
    if(props.query && props.query.c === "arzanwealth") {
      setLogo({url: arzanWealthLogo, h: 100, w: 78});
    }
  }, []);

  function handleSubmit(event) {
    props.resetPassword(email);
    event.preventDefault();
  }

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <div>
        <img src={`${logo.url}`} alt="Pentugram" height={`${logo.h}`} width={`${logo.w}`} />
      </div>
      <h1>Investor Portal</h1>
      <Typography.Title level={4}>Reset Your Password</Typography.Title>
      <Typography.Text strong>Send password via email</Typography.Text>
      <div className="form-field">
        <i className="fas fa-user" />
        <input
          type="email"
          name="email"
          id="email"
          className="form-field"
          placeholder=" "
          required
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <label htmlFor="email">Email</label>
      </div>
      <CustomButton
        label={props.loading ? " " : "Reset Password"}
        disabled={props.loading}
        // icon="login"
        isLoading={props.loading}
        className="primary"
        size="large"
        block={true}
        htmlType="submit"
      />
      <div>
        <a className="login-form-forgot" onClick={() => props.changeView(1)}>Back</a>
      </div>
    </form>
  );
}
