import React from 'react';
import {
  Router,
  Route,
  browserHistory,
  Redirect,
  IndexRoute,
  IndexRedirect,
} from 'react-router';
import api from "ReduxActions/api";
import { syncHistoryWithStore } from 'react-router-redux';

import Signin from './components/auth/Signin';
import HeaderLayout from './components/app/HeaderLayout';
import DashboardContext from "./components/dashboard/DashboardContext";
import PortfolioContext from "./components/portfolio/PortfolioContext";
import DealContext from './components/portfolio/DealContext';
import DistributionContext from './components/portfolio/DistributionContext';
import NotesContext from './components/portfolio/NotesContext';
import FilesContext from './components/portfolio/FilesContext';
import DiversificationContext from "./components/diversification/DiversificationContext";
import ProfileContext from './components/profile/ProfileContext';
import ExitedContext from './components/ExitedDeals/ExitedContext';

export default function(store) {
  const history = syncHistoryWithStore(browserHistory, store);
  const token = localStorage.getItem("token");

  const requireAuth = (nextState, replace, callback) => {
    api
      .get("/me")
      .then(res => {
        callback();
      })
      .catch(err => {
        replace({ pathname: "/signin" });
        callback();
      });
  };

  const preventLogin = (nextState, replace, callback) => {
    if(token) {
      api
        .get("/me")
        .then(res => {
          replace({ pathname: "/" });
          callback();
        })
        .catch(err => callback());
    } else callback();
  };

  return (
    <Router history={history}>
      <Route onEnter={requireAuth} path="/" component={HeaderLayout}>
        {/* <IndexRoute component={DashboardContext} /> */}
        <IndexRedirect from="" to="/portfolio" />
        <Route path="portfolio">
          <IndexRoute component={PortfolioContext} />
          <Route path=":id" component={DealContext}>
            <IndexRedirect to="distribution" />
            <Route path="distribution" component={DistributionContext} />
            <Route path="notes" component={NotesContext}/>
            <Route path="files" component={FilesContext} />
          </Route>
        </Route>
        <Route path="exited">
          <IndexRoute component={ExitedContext} />
          <Route path=":id" component={DealContext}>
            <IndexRedirect to="distribution" />
            <Route path="distribution" component={DistributionContext} />
            <Route path="notes" component={NotesContext}/>
            <Route path="files" component={FilesContext} />
          </Route>
        </Route>
        <Route path="diversification" component={DiversificationContext} />
        <Route path="profile" component={ProfileContext} />
      </Route>
      <Route onEnter={preventLogin} path="/signin" component={Signin} />
      <Redirect from="*" to="/" />
    </Router>
  );
}
