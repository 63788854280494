import api from "ReduxActions/api";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Descriptions, message, Spin, Icon } from "antd";

import "./InvestmentsInfo.scss";

const formatNumber = amount => {
  return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export default function InvestmentsInfo({ dealId, currency, name}) {
  const [ loading, setLoading ] = useState(false);
  const [ investments, setInvestments ] = useState([]);
  const spinner = <Icon type="loading" style={{ fontSize: 24 }} spin />

  useEffect(() => {
    loadInvestments();
  }, []);

  const loadInvestments = () => {
    setLoading(true);
    api
      .post("/investor-dists/investments", { deal_id: dealId })
      .then(({data}) => {
        setLoading(false);
        setInvestments(data);
      })
      .catch(err => {
        setLoading(false);
        message.error('Someting went wrong!');
      })
  }

  return (
    <div className="InvestmentsInfo">
      {loading ? (<Spin tip="Loading..." indicator={spinner}>
        <Descriptions bordered size="small" column={1}>
          <Descriptions.Item span={1} label="Amount invested"></Descriptions.Item>
          <Descriptions.Item span={1} label="Date of investment"></Descriptions.Item>
          {/* <Descriptions.Item span={1} label="Date of exit"></Descriptions.Item> */}
        </Descriptions>
      </Spin>) : (
        <div>
          {
            investments.map(inv => (
              <div key={inv.deal_investor_id} className="descriptionsItems">
                <Descriptions bordered size="small" column={1}>
                  <Descriptions.Item span={1} label="Amount invested">{`${formatNumber(inv.investment_amount)} ${currency}`}</Descriptions.Item>
                  <Descriptions.Item span={1} label="Date of investment">{moment(inv.date_of_acquisition * 1000).format("ll") || "N/A"}</Descriptions.Item>
                  {/* <Descriptions.Item span={1} label="Date of exit">{inv.date_of_exit ? moment(inv.date_of_exit * 1000).format("ll") : "N/A"}</Descriptions.Item> */}
                </Descriptions>
              </div>
            ))
          }
        </div>
      )}
    </div>
  );
};

