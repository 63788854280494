import { Upload, Icon, message } from "antd";
import React, { useState } from "react";

import "./input.scss";

export default function ProfileImage(porps) {
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const uploadButton = (
    <div>
      <Icon type={loading ? "loading" : "plus"} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const ImageContainer = (
    <div>
      {loading ? (
        <Icon type="loading" />
      ) : (
        <img src={porps.input.value} alt="avatar" style={{ width: "100%" }} />
      )}
    </div>
  );

  const customRequest = it => {
    setLoading(true);
    let url = process.env.CLOUDINARY_URL_UPLOAD;
    let xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

    xhr.onreadystatechange = e => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        let response = JSON.parse(xhr.responseText);
        let url = response.secure_url;

        setLoading(false);
        porps.input.onChange(url);
      }
    };

    xhr.onerror = e => {
      setLoading(false);
      message.error("Something went wrong, try again");
    };

    let data = new FormData();
    data.append("file", it);

    data.append("upload_preset", process.env.CLOUDINARY_UPLOAD_PRESET);
    data.append("tags", "browser_upload");
    xhr.send(data);
  };

  return (
    <div className="ProfileImage">
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action={customRequest}
        beforeUpload={beforeUpload}
      >
        {porps.input.value ? ImageContainer : uploadButton}
      </Upload>
    </div>
  );
}

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}
