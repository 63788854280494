import React  from "react";
import { Popover, Button, Radio } from 'antd';
import { COLOR_PALETTES_LIST, mapPaletteValueToPaletteName } from "Enums/colors";

import "./PalettePicker.scss";

export function PalettePicker(props) {

  const content = (
    <div>
      <Radio.Group onChange={(e) => props.onChange(e.target.value)} value={props.color}>
        {
          COLOR_PALETTES_LIST.map(palette => (
            <Radio key={palette} style={radioStyle} value={palette}>
              {mapPaletteValueToPaletteName(palette)}
            </Radio>
          ))
        }
      </Radio.Group>
    </div>
  );

  return <div className="PalettePicker">
    <Popover placement="topRight" title="Chart color palettes:" content={content} trigger="click">
      <Button shape="circle" icon="bg-colors"></Button>
    </Popover>
  </div>
}

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};