import React from "react";
import { Link } from "react-router";
import { DropdownMenu, CustomAvatar } from "SharedComponents";

export const UserInfo = props => {
  const items = [
    { label: <Link to="/profile">Account settings</Link>, tag: "Item", },
    { tag: "Divider" },
    { label: "Logout", onClick: () => props.signOut(), tag: "Item", },
  ];
  return (
    <div className="UserInfo">
      <DropdownMenu items={items} placement="bottomLeft">
        <div>
          <span className="hi-details mr-2">Hi,</span>
          <span className="name-details text-ellipsis mr-2">
            {props.profile && props.profile.fullname}
          </span>

          <CustomAvatar size={48} src={(props.profile && props.profile.image) || `https://ui-avatars.com/api?name=${props.profile && props.profile.fullname}`} />
        </div>
      </DropdownMenu>
    </div>
  );
};
