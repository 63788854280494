import React from "react";
// import { Progress } from 'antd';

import "./Container.scss";

export const Container = props => (
  <div className="Container-details" style={{ margin: props.margin }}>
    {/* <Progress
      strokeColor="#1890ff"
      percent={100}
      status="active"
      showInfo={props.showInfo}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    /> */}
    <div style={{ padding: props.padding, minHeight: props.minHeight}}>
      {props.children}
    </div>
  </div>
);

Container.defaultProps = {
  padding: 8,
  margin: 0,
  width: "100%",
  // showInfo: false,
  // strokeWidth: 3,
  // strokeLinecap: "square"
}

