import React from "react";
import Icon from "antd/lib/icon";
import "./Folder.scss";

function getType(url) {
  const array = url.split(".");
  const type = array[array.length - 1];
  if (type == "xlsx") return "xls";
  else if (type == "pptx") return "ppt";
  return type;
}

function tryRequire(file) {
  try {
    return require(`./fileTypes/${file}.svg`);
  } catch (err) {
    return require(`./fileTypes/file.svg`);
  }
}

class Folder extends React.Component {
  render() {
    const { props } = this;

    if (props.is_leaf)
      return (
        <FileContainer
          file_type={props.file_type}
          title={props.title}
          color="#8f8f8f"
          style={props.style}
          openFile={props.openFile}
        />
      );
    return (
      <FolderContainer
        color="#8f8f8f"
        title={props.title}
        style={props.style}
        openFolder={props.openFolder}
      />
    );
  }
}

const FolderContainer = props => (
  <div className="Folder flex-start m-2" style={props.style} onClick={props.openFolder}>
    <Icon
      type="folder"
      theme="filled"
      style={{ color: props.color, fontSize: 26 }}
    />
    <span className="folder-name ml-2">{props.title}</span>
  </div>
);

const FileContainer = props => (
  <div className="Folder flex-start m-2" style={props.style} onClick={props.openFile}>
    <img height="30" width="30" src={tryRequire(getType(props.file_type))} />
    <span className="folder-name ml-2">{props.title}</span>
  </div>
);

export default Folder;
