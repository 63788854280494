import React from "react";
import { Link } from "react-router";
import Breadcrumb from "antd/lib/breadcrumb";
import Icon from "antd/lib/icon";

export const BreadcrumbLayers = props => {
  function itemRender(route, params, routes, paths) {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>
        {route.icon && <Icon type={route.icon} />}
        {route.breadcrumbName && <span>{route.breadcrumbName}</span>}
      </span>
    ) : (
      <span>
        {props.isLocal ? (
          <span className="hover-40a9ff" onClick={() => props.onPathClicked(route)}>
            {route.icon && <Icon type={route.icon} />}
            {route.breadcrumbName && <span>{route.breadcrumbName}</span>}
          </span>
        ) : (
          <Link to={route.path}>
            {route.icon && <Icon type={route.icon} />}
            {route.breadcrumbName && <span>{route.breadcrumbName}</span>}
          </Link>
        )}
      </span>
    );
  }

  return (
    <div className="BreadcrumbLayers">
      <Breadcrumb
        separator={props.separator}
        itemRender={itemRender}
        routes={props.routes}
      />
    </div>
  );
};

BreadcrumbLayers.defaultProps = {
  separator: "/",
  isLocal: false,
};
