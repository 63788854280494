import React, { useEffect } from "react";
import { Table, Collapse, Row, Col } from "antd";
import { numberRoundFormatter, formatDecimalNumber } from "Utils/formatNumber.js";

import { Spinner, EmptyData } from "SharedComponents";

import "./PortfolioTable.scss";

export function PortfolioTable({ distribution, loadInvestments, openDeal, loading, defaultKeys }) {
  useEffect(() => {
    loadInvestments();
  }, []);

  const columns = (period) => [
    {
      title: "Entity",
      width:150,
      dataIndex: "name",
      render: (text, it) => <span style={{ fontWeight: 600 }}>{text} {it.svp_name && `( ${it.svp_name} )`}</span>,
    },
    {
      title: "Amount Invested",
      width:150,
      dataIndex: "investment_amount",
      render: (text, it) =>text?<span>{numberRoundFormatter(text, it.currency)}</span>:'',
    },
    {
      title: "Rate of return",
      width:150,
      dataIndex: "rate",
      render: (text, it) => text?<span>{formatDecimalNumber(text)} %</span>:'',
    },
    {
      title: `${period} distributions`,
      width:150,
      dataIndex: "return_rate",
      render: (text, it) => <span>{numberRoundFormatter(text, it.currency)}</span>,
    },
    {
      title: "Cumulative distribution",
      width:150,
      dataIndex: "return_rate_total",
      render: (text, it) => <span>{numberRoundFormatter(text, it.currency)}</span>,
    },
    // {
    //   title: "Annulized",
    //   dataIndex: "annulized",
    // },
  ];

  function renderFooter(data)
  {
    return  [{
      "rate": '',
      "return_rate": data.reduce((sum, record) => sum + record.return_rate, 0),
      "return_rate_total": data.reduce((sum, record) => sum + record.return_rate_total, 0),
      "name": "Total",
      "total_invested_amount": '',
      "investment_amount":data.reduce((sum, record) => sum + record.investment_amount, 0)
    }]
  }
  const footerData=[{
    "rate": '',
    "return_rate": 6.25,
    "rate_date": "Jan 31,21",
    "return_rate_total": 225,
    "name": "Total",
    "total_invested_amount": '',
  }]
  const currencyPanelStyle = {
    background: "#f9f9f9",
  };


  return (
    <div className="PortfolioTable">
      {loading ? (
        <div className="flex-center">
          <Spinner isLoading={loading} />
        </div>
      ) : (
        <div className="">
          {
            Object.keys(distribution).map(period => (
              <div key={period} className="mb-4">
                <Collapse defaultActiveKey={defaultKeys.period} expandIconPosition="right">
                  <Collapse.Panel header={<div className="period-block">{period} Distribution</div>} key={period} style={currencyPanelStyle}>
                    {
                      distribution[period].length === 0  ? (
                        <EmptyData description={`No ${period} distribution`} />
                      ) : (
                        Object.keys(distribution[period]).map(currency => (

                          <div className="m-2" key={period + currency}>
                            <Collapse defaultActiveKey={defaultKeys.currency} expandIconPosition="right">
                              <Collapse.Panel header={<div className="currency-block">All amounts in {currency}</div>} key={currency} style={currencyPanelStyle}>
                                <Table
                                  pagination={false}
                                  bordered={true}
                                  columns={columns(period)}
                                  rowKey={(record, key) => key}
                                  dataSource={distribution[period][currency]}
                                  rowClassName="table-row-style"
                                  onRow={(record, rowIndex) => {
                                    return {
                                      onClick: event => openDeal(record.deal_id),
                                    };
                                  }}
                                  footer={(data) => (
                                    <Table
                                      pagination={false}
                                      columns={columns(period)}
                                      rowKey={(record, key) => key}
                                      dataSource={renderFooter(data)}
                                      rowClassName="table-row-style"
                                      showHeader={false}
                                      style={{padding:"0"}}
                                      />
                                  )}
                                />
                              </Collapse.Panel>
                            </Collapse>
                          </div>
                        ))
                      )
                    }
                  </Collapse.Panel>
                </Collapse>
              </div>
            ))
          }
        </div>
      )}
    </div>
  );
}
