import api from "./api";
import { Notifier } from "SharedComponents";

export const LOADING_CHARTS = "diversification:charts:loading";
export const CHARTS_LOADED = "diversification:charts:loaded";

export function loadCharts() {
  return dispatch => {
    dispatch({ type: LOADING_CHARTS, payload: true });
    api.get(`/charts`)
      .then(({ data }) => {
        dispatch({ type: CHARTS_LOADED, payload: data });
      })
      .catch(({response}) => {
        dispatch({ type: LOADING_CHARTS, payload: false });
        Notifier({
          type: "error",
          title: "Could not load diversification charts",
          message: "Please try again",
        });
      });
  }
}