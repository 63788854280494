import React from "react";
import { browserHistory } from "react-router";
import notification from "antd/lib/notification";
import { CustomButton } from "SharedComponents";

export const Notifier = ({ type, title, message, placement, redirect }) => {
  const notifType = type || "open";
  const key = `open${Date.now()}`;
  const actionButton = (
    <CustomButton
      label="Show details"
      style={{ width: "150px" }}
      onClick={() => {
        notification.close(key);
        browserHistory.push(redirect);
      }}
    />
  );

  notification[notifType]({
    message: title,
    description: message,
    placement: placement || "bottomRight",
    btn: redirect ? actionButton : null,
    key,
  });
};

// type: open, success, warning, info, error
// placement: topLeft, topRight, bottomLeft, bottomRight
