import api from "ReduxActions/api";
import { Modal, Form, message } from "antd";
import React, { useState } from "react";
import { Password } from "./Input";

function ResetPassword(props) {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleCancel = () => {
    props.handleCancel();
    handleReset();
  }

  const handleReset = () => {
    props.form.resetFields();
  };

  const handleSubmit = e => {
    e.preventDefault();
    setConfirmLoading(true);
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        api
          .post("/me/updatepassword", values)
          .then(res => {
            message.success('Your password has been successfully updated.');
            setConfirmLoading(false);
            handleCancel();
          })
          .catch(err => {
            setConfirmLoading(false);
            message.error('Something went wrong. Try again later');
          })
      } else setConfirmLoading(false);
    });
  };

  const checkLength = (rule, value, callback) => {
    if (value.length > 5) {
      callback();
      return;
    }
    callback('Minimum 6 characters!');
  };

  const compareToFirstPassword = (rule, value, callback) => {
    const { form } = props;
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  return (
    <div>
      <Modal
        title="Change Password"
        closable={false}
        visible={props.visible}
        confirmLoading={confirmLoading}
        onOk={handleSubmit}
        okText="Save"
        okButtonProps={{ htmlType: "submit" }}
        onCancel={handleCancel}
        cancelText="Cancel"
        cancelButtonProps={{}}
      >
        <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <Form.Item>
                {props.form.getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      message: "Current Password is required",
                    },
                    {
                      validator: checkLength
                    }
                  ],
                })(
                  <Password
                    label="Current Password"
                    placeholder="Current Password"
                    required={true}
                  />
                )}
              </Form.Item>
            </div>
            <div className="col-md-12">
              <Form.Item>
                {props.form.getFieldDecorator("newPassword", {
                  rules: [
                    {
                      required: true,
                      message: "Fiels required",
                    },
                    {
                      validator: checkLength
                    }
                  ],
                })(
                  <Password
                    label="New Password (minimum 6 characters)"
                    placeholder="New Password (minimum 6 characters)"
                    required={true}
                  />
                )}
              </Form.Item>
            </div>
            <div className="col-md-12">
              <Form.Item>
                {props.form.getFieldDecorator("confirmPassword", {
                  rules: [
                    {
                      required: true,
                      message: "Fiels required",
                    },
                    {
                      validator: checkLength
                    },
                    {
                      validator: compareToFirstPassword,
                    },
                  ],
                })(
                  <Password
                    label="Confirm New Password"
                    placeholder="Confirm New Password"
                    required={true}
                  />
                )}
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
}

ResetPassword = Form.create({ name: "reset-password-form" })(ResetPassword);

export default ResetPassword;
