import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {loadExited,resetExited } from "ReduxActions/portfolioActions";
import { PortfolioGrid } from "../portfolio/PortfolioGrid";

import "./ExitedContext.scss";

const actions = {loadExited,resetExited };

class ExitedContext extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.loadExited();
  }

  componentWillUnmount()
  {
    this.props.resetExited();
  }

  renderView() {
        return (
          <PortfolioGrid
            investments={this.props.exitedInvestment}
            loading={this.props.loading}
            openDeal={deal_id => this.props.router.push(`exited/${deal_id}`)}
          />
        )
  }
  render() {
    return (
      <div className="PortfolioContext">
        {this.renderView()}
      </div>
    );
  }
}
function mapStateToProps({portfolio}) {
  return {
    exitedInvestment:portfolio.exited,
    loading:portfolio.exitedLoading
  };
}
export default connect(mapStateToProps, actions)(withRouter(ExitedContext));
