import React, { Component } from "react";
import { connect } from "react-redux";
import { List, Icon, Empty, message } from "antd";
import moment from "moment";
import { OverlaySpinner } from "SharedComponents";
import InfiniteScroll from 'react-infinite-scroller';
import { loadNotes, emptyItems } from "ReduxActions/dealActions";

import "./NotesContext.scss";

class NotesContext extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hasMore: true,
    };
  }

  componentDidMount() {
    this.props.loadNotes(this.props.params.id, 1, 10);
  }

  handleInfiniteOnLoad(currentPage) {
    if (currentPage == this.props.meta.pageCount) {
      message.warning('All notes are loaded');
      this.setState({ hasMore: false });
      return;
    }
    this.props.loadNotes(this.props.params.id, currentPage + 1, this.props.meta.perPage);
  }

  htmlDecode(input) {
    let e = document.createElement("div");
    e.innerHTML = input;
    return e.innerHTML;
  }

  componentWillUnmount() {
    this.props.emptyItems("notes");
  }

  render() {
    console.log(111,this.props.notes);
    return (
      <div className="NotesContext">
        <OverlaySpinner isLoading={this.props.loading}>
       {this.props.notes.length ?  <InfiniteScroll
          loader={<div className="loader" key={0} />}
          initialLoad={false}
          pageStart={0}
          loadMore={this.handleInfiniteOnLoad.bind(this)}
          hasMore={!this.state.loading && this.state.hasMore}
        >
        {this.props.notes.map(note=> <div key={note.note_id + 1} className='note-content'>
          <div  dangerouslySetInnerHTML={{ __html: note.content }} />
          <div className='note-files'>
            {(note.files || []).map((file, i) => (
              <div className='file' key={i + 1} onClick={()=>window.open(file.url)}>
                <Icon type="paper-clip"  />
                <div className='file-title' >
                  {file.title}
                </div>
              </div>
            ))}
          </div>
            <span className='file-create'>Created at: {moment(note.created_at * 1000).fromNow()}</span>
        </div>)}
        </InfiniteScroll>:
        <Empty description='No notes'/>}
        </OverlaySpinner>
      </div>
    );
  }
}

function mapStateToProps({ deal }) {
  return {
    notes: deal.notes,
    meta: deal.meta,
    loading: deal.loadingItems,
  };
}

export default connect(mapStateToProps, { loadNotes, emptyItems })(NotesContext);
