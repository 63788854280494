import React, { Component } from "react";
import Icon from "antd/lib/icon";
import Radio from "antd/lib/radio";

class GroupButton extends Component {
  render() {
    const { props } = this;
    return (
      <div className="GroupButton">
        <Radio.Group
          onChange={(e) => props.handlechange(e.target.value)}
          defaultValue={props.defaultValue}
          size={props.size}
          buttonStyle={props.buttonStyle}
        >
          {props.options.map(opt => (
            <Radio.Button
              key={opt.value + 1}
              value={opt.value}
              disabled={opt.disabled}
            >
              <span style={{color: "transparent"}}>.</span>
              {opt.icon && <Icon type={opt.icon} theme={opt.theme} />}
              {opt.label}
              <span style={{color: "transparent"}}>.</span>
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
    );
  }
}

export default GroupButton;

GroupButton.defaultProps = {
  size: "default",
  buttonStyle: "solid",
};
