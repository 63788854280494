import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CustomButton } from "SharedComponents";
import { signIn, resetPassword } from "ReduxActions/authActions";
import ResetPassword from "./ResetPassword";
import pentugramLogo from "Assets/images/Gold@3x.svg";
import arzanWealthLogo from "Assets/images/arzanWealth.png";

import "./Signin.scss";

const actions = { signIn, resetPassword };

function Signin(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [view, setView] = useState(1);
  const [logo, setLogo] = useState({ url: pentugramLogo, h: 78, w: 78});

  useEffect(() => {
    if(props.location.query && props.location.query.c === "arzanwealth") {
      setLogo({url: arzanWealthLogo, h: 100, w: 78});
    }
  }, []);

  const handleSubmit = event => {
    props.signIn({ email, password });
    event.preventDefault();
  };

  return (
    <div className="Signin">
      {view == 1 ? (
        <form className="login-form" onSubmit={handleSubmit}>
          <div>
            <img
              src={`${logo.url}`}
              alt="Pentugram"
              height={`${logo.h}`}
              width={`${logo.w}`}
            />
          </div>
          <h1>Investor Portal</h1>
          <div className="form-field">
            <i className="fas fa-user" />
            <input
              type="email"
              name="email"
              id="email"
              className="form-field"
              placeholder=" "
              required
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <label htmlFor="email">Email</label>
          </div>
          <div className="form-field">
            <i className="fas fa-lock" />
            <input
              type="password"
              name="password"
              id="password"
              className="form-field"
              placeholder=" "
              required
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <label htmlFor="password">Password</label>
          </div>
          <CustomButton
            label={props.loading ? " " : "Login"}
            disabled={props.loading}
            icon="login"
            isLoading={props.loading}
            className="primary"
            size="large"
            block={true}
            htmlType="submit"
          />
          <div>
            <a className="login-form-forgot" onClick={() => setView(2)}>
              Forgot password ?
            </a>
          </div>
        </form>
      ) : (
        <ResetPassword
          changeView={(view) => setView(view)}
          resetPassword={props.resetPassword}
          query={props.location.query}
        />
      )}
    </div>
  );
}

function mapStateToProps({ auth }) {
  return {
    loading: auth.loading,
  };
}

export default connect(mapStateToProps, actions)(Signin);
