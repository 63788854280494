import { INVESTEMENTS_LOADED, INVESTEMENTS_LOADING, INVESTEMENTS_DISTRIBUTION_LOADED, EXITED_INVESTEMENTS_LOADED, EXITED_INVESTEMENTS_LOADING, EXITED_INVESTEMENTS_RESET } from "ReduxActions/portfolioActions";

const initialState = {
  all: [],
  exited:[],
  loading: false,
  exitedLoading:false,
  distribution: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case INVESTEMENTS_LOADED:
      return {
        ...state,
        loading: false,
        all: action.payload,
      }
    case EXITED_INVESTEMENTS_LOADED:
      return {
        ...state,
        exitedLoading: false,
        exited: action.payload,
      }
    case EXITED_INVESTEMENTS_RESET:
      return {
        ...state,
        exitedLoading: false,
        exited: [],
      }
    case INVESTEMENTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case EXITED_INVESTEMENTS_LOADING:
      return {
        ...state,
        exitedLoading: action.payload,
      };
    case INVESTEMENTS_DISTRIBUTION_LOADED:
      return {
        ...state,
        loading: false,
        distribution: action.payload,
      }

    default:
      return state;
  }
}
