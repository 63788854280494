import api from "./api";
import { message } from "antd";
import { Notifier } from "SharedComponents";
import { browserHistory } from "react-router";

export const SIGNING_IN = "auth:signing:in";
export const SIGNED_IN = "auth:signed:in";
export const SIGNED_OUT = "auth:sign:out";

export function signIn({ email, password }) {
  return dispatch => {
    dispatch({ type: SIGNING_IN, payload: true });
    api.post("/authentication", { email, password })
    .then(({ data }) => {
      localStorage.setItem("token", data.token);
      dispatch({ type: SIGNING_IN, payload: false });
      browserHistory.push("/");
    })
    .catch(({response}) => {
      dispatch({ type: SIGNING_IN, payload: false });
      const message =  response && response.data ? response.data.message : "An error has been detected";
      Notifier({
        type: "error",
        title: message,
        message: "",
      });
    });
  }
}

export function signOut() {
  localStorage.clear();
  browserHistory.push("/signin");
  return dispatch => {
    dispatch({ type: SIGNED_OUT });
  }
}

export function checkToken() {
  const storedToken = localStorage.getItem("token");

  if (!storedToken) {
    browserHistory.push("/signin");
    return;
  }

  return dispatch => {
    api
      .get("/me")
      .then(({ data }) => {
        dispatch({
          type: SIGNED_IN,
          payload: data,
        });

        localStorage.setItem("token", data.token);
      })
      .catch(() => {
        browserHistory.push("/signin");
      });
  };
}

export function updateMe(me) {
  return dispatch => {
    api
      .post("/me", me)
      .then(({ data }) => {
        message.success(`Your profile has been successfully updated.`);
        dispatch({ type: SIGNED_IN, payload: data });
      })
      .catch(error => {
        message.error(`Something went wrong.`);
      });
  };
};

export function resetPassword(email) {
  return dispatch => {
    api
      .post("me/resetpassword", { email })
      .then(() => {
        message.success("Your password has been reset successfully! Check your email address.");
      })
      .catch(error => {
        message.error((error && error.response && error.response.data && error.response.data.message) || "An error has been detected");
      });
  };
};
