import React from "react";
import Dropdown from "antd/lib/dropdown";
import Menu from "antd/lib/menu";

const DropdownMenu = props => {
  const menu = (
    <Menu>
      {props.items.map((item, index) => {
        const CustomTag = Menu[item.tag];
        return (
          <CustomTag key={index + 1} onClick={item.onClick}>
            {item.label}
          </CustomTag>
        );
      })}
    </Menu>
  );
  return (
    <div className={props.className}>
      <Dropdown overlay={menu} placement={props.placement}>
        {props.children}
      </Dropdown>
    </div>
  );
};

DropdownMenu.defaultProps = {
  placement: "bottomLeft",
  items: [],
};

export default DropdownMenu;
