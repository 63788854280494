import React, { Component } from "react";
import { connect } from "react-redux";

import { Modal, Button } from "antd";
import { loadDeal } from "ReduxActions/dealActions";
import { OverlaySpinner } from "SharedComponents";
import { AssetCard } from "../AssetCard";
import InvestmentsInfo from "../InvestmentsInfo";

const actions = { loadDeal };

class DealInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInvestments: false,
    }
  }

  componentDidMount() {
    this.props.loadDeal(this.props.dealId);
  }

  closeInvestorInfo() {
    this.setState({ showInvestments: false });
  }

  getCurrency(currency) {
    return currency
      ? currency.symbol
        ? currency.symbol
        : currency.code
      : "--";
  }

  render() {
    return (
      <div className="DealInfo">
        <OverlaySpinner isLoading={this.props.loading}>
          <AssetCard
            {...this.props.editing}
            showInvestmentDate={true}
            showFrequency={true}
            showInvestments={() => this.setState({ showInvestments: true })}
          />
        </OverlaySpinner>
        {this.state.showInvestments && (
          <Modal
            title="Investments details"
            visible={this.state.showInvestments}
            onCancel={() => this.closeInvestorInfo()}
            closable={false}
            bodyStyle={{ maxHeight: 600, overflow: "auto" }}
            footer={[
              <Button key="back" onClick={() => this.closeInvestorInfo()}>
                Cancel
              </Button>
            ]}
          >
            <InvestmentsInfo
              dealId={this.props.editing && this.props.editing.deal_id}
              currency={this.getCurrency(this.props.editing && this.props.editing.currencyName)}
            />
          </Modal>
        )}
      </div>
    );
  }
}


function mapStateToProps({deal}) {
  return {
    editing: deal.editing,
    loading: deal.loading,
  };
}

export default connect(mapStateToProps, actions)(DealInfo);