
export const formatDecimalNumber = (number, decimal = 2) => {
  if(!number) return ""
  return number.toFixed(decimal).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export function moneyFormatter(number = 0, currency = "USD", fraction = 2, language = "en-US") {
  return new Intl.NumberFormat(language, {
    style: "currency",
    currency: currency || "USD",
    minimumFractionDigits: fraction,
  }).format(number);
};

export function numberFormatter(number = 0, currency = "USD", fraction = 2, language = "en-US") {
  return new Intl.NumberFormat(language, {
    minimumFractionDigits: fraction,
  }).format(number);
};

export function numberRoundFormatter(number = 0, currency = "USD", fraction = 0, language = "en-US") {
  return new Intl.NumberFormat(language, {
    style: "currency",
    currency: currency || "USD",
    minimumFractionDigits: fraction,
  }).format(roundNumber(number));
};

export function roundNumber(number) {
  if(!number) return 0;
  if(isNaN(number)) return 0;
  return Math.round(number);
}