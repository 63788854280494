import { Input, Typography, Badge } from "antd";

import React, { forwardRef } from "react";

export function InputText(props) {
  return (
    <div>
      {props.label
        ? props.required
          ? <Badge dot offset={[ 10, 5]}><Typography.Text type={props.labelType} strong>{props.label}</Typography.Text></Badge>
          : <Typography.Text type={props.labelType} strong>{props.label}</Typography.Text>
        : null
      }
      <Input
        {...props.input}
        placeholder={props.placeholder}
        size={props.size}
        style={props.style}
        addonBefore={props.addonBefore}
        addonAfter={props.addonAfter}
        prefix={props.prefix}
        suffix={props.suffix}
        disabled={props.disabled}
        allowClear={props.allowClear}
      />
    </div>
  );
};

InputText.defaultProps = {
  size: "large",
  labelType: "secondary", 
}

export function WrappedPassword(props, ref) {
  return (
    <div ref={ref}>
      {props.label
        ? props.required
          ? <Badge dot offset={[ 10, 5]}><Typography.Text type={props.labelType} strong>{props.label}</Typography.Text></Badge>
          : <Typography.Text type={props.labelType} strong>{props.label}</Typography.Text>
        : null
      }
      <Input.Password
        {...props}
        placeholder={props.placeholder}
        size={props.size || "large"}
        style={props.style}
        addonBefore={props.addonBefore}
        addonAfter={props.addonAfter}
        prefix={props.prefix}
        suffix={props.suffix}
        disabled={props.disabled}
      />
    </div>
  );
}

export const Password = forwardRef(WrappedPassword);
