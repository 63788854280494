import { Tabs, Icon } from "antd";
import React, { Component } from "react";
import { BreadcrumbLayers, Container } from "SharedComponents";
import DealInfo from "../DealInfo";

import "./DealContext.scss";

export default class DealContext extends Component {
  get lastLocation() {
    return this.props.location.pathname.split("/").pop();
  }
  get routes() {
    const last = this.lastLocation;
    const lastname = last ? last.charAt(0).toUpperCase() + last.slice(1) : "";
    return [
      { breadcrumbName: "My Portfolio", path: "/portfolio", icon: "" },
      { breadcrumbName: lastname, path: `/${last}`, icon: "" },
    ];
  }

  render() {
    const tabBasePath=this.props.location.pathname.split("/")[1];
    const tabs = [
      { path: "distribution", title: "Distribution", icon: "pie-chart" },
      { path: "notes", title: "Notes", icon: "profile" },
      { path: "files", title: "Files", icon: "folder-open" },
    ];
    return (
      <div className="DealContext">
        <div className="m-3 mb-4">
          <BreadcrumbLayers routes={this.routes} />
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-3">
            <DealInfo
              dealId={this.props.params.id}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-7 col-lg-9">
            <div className="card-container">
              <Container padding={8} minHeight="100%">
                <Tabs
                  type="card"
                  tabBarGutter={4}
                  activeKey={this.lastLocation}
                  tabBarStyle={{ display: "flex", justifyContent: "center" }}
                  onTabClick={path => this.props.router.push(`/${tabBasePath}/${this.props.params.id}/${path}`)}
                >
                  {tabs.map(tab => (
                    <Tabs.TabPane
                      key={tab.path}
                      tab={
                        <span>
                          <Icon type={tab.icon} />
                          {tab.title}
                        </span>
                      }
                    />
                  ))}
                </Tabs>
                <div className="p-2">{this.props.children}</div>
              </Container>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
