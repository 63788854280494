import React from "react";
import CountUp from "react-countup";
import Card from "antd/lib/card";
import Icon from "antd/lib/icon";

import "./NumberCard.scss";

function NumberCard({ icon, color, title, number, countUp }) {
  return (
    <div className="NumberCard">
      <Card className="number-card" bordered={true} bodyStyle={{ padding: 24 }}>
        <Icon className="number-card-icon-warp" style={{ color }} type={icon} />
        <div className="number-card-content">
          <p className="number-card-title number-card-text-overflow">
            {title || "No Title"}
          </p>
          <p className="number-card-number number-card-text-overflow">
            <CountUp
              start={0}
              end={number}
              duration={2.75}
              useEasing
              useGrouping
              separator=","
              {...countUp || {}}
            />
          </p>
        </div>
      </Card>
    </div>
  );
}

export default NumberCard;
