import {
  DEAL_LOADING,
  DEAL_LOADED,
  LOADING_ITEMS,
  DEAL_NOTES_LOADED,
  DEAL_FILES_LOADED,
  PATH_FOLDERS_UPDATED,
  EMPTY_ITEMS,
  DEAL_DISTRIBUTION_LOADED,
} from "ReduxActions/dealActions";

const initialState = {
  editing: null,
  loading: false,
  notes: [],
  files: [],
  filesPath: [],
  distribution: [],
  loadingItems: false,
  meta: {
    currentPage: 1,
    perPage: 10,
    pageCount: 1,
    totalCount: 10,
  }
};

function extractPath(folders, folder) {
  if(folder) {
    const path = folders.findIndex(it => it.path == folder.path );
    if(path < 0) return [...folders, folder];
    folders.splice(path + 1, folders.length);
    return folders;
  }
  return [];
}

export default function(state = initialState, action) {
  switch (action.type) {
    case DEAL_LOADED:
      return {
        ...state,
        loading: false,
        editing: action.payload,
      };

    case DEAL_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case LOADING_ITEMS:
      return {
        ...state,
        loadingItems: action.payload,
      };

    case DEAL_NOTES_LOADED:
      return {
        ...state,
        notes: [...state.notes, ...action.payload],
        meta: action.meta,
        loadingItems: false,
      };

    case DEAL_FILES_LOADED:
      return {
        ...state,
        loadingItems: false,
        files: action.payload,
      };

    case PATH_FOLDERS_UPDATED:
      return {
        ...state,
        filesPath: extractPath(state.filesPath, action.payload), 
      };

    case EMPTY_ITEMS:
      return {
        ...state,
        [action.payload]: [],
        meta: {
          currentPage: 1,
          perPage: 10,
          pageCount: 1,
          totalCount: 10,
        }
      };
    
    case DEAL_DISTRIBUTION_LOADED:
      return {
        ...state,
        distribution: action.payload,
        meta: action.meta,
        loadingItems: false,
      }

    default:
      return state;
  }
}
