import React from "react";
import Tag from "antd/lib/tag";
import { BreadcrumbLayers } from "SharedComponents";

const FilesPath = props => {
  const files = props.files.filter(it => it.is_leaf).length;
  const folders = props.files.filter(it => !it.is_leaf).length;
  return (
    <div className="flex-between">
      <BreadcrumbLayers
        isLocal={true}
        routes={props.path}
        onPathClicked={props.loadFiles}
      />
      <div className="flex-end">
        <Tag>{folders} Folders</Tag>
        <Tag>{files} Files</Tag>
      </div>
    </div>
  );
};

export default FilesPath;
