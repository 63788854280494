import { LOADING_CHARTS, CHARTS_LOADED } from "ReduxActions/diversificationActions";

const initialCharts = {
  country: [],
  currency: [],
  rate: [],
  sector: [],
}

const initialState = {
  charts: initialCharts,
  loading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHARTS_LOADED:
      return {
        ...state,
        loading: false,
        charts: action.payload,
      }

    case LOADING_CHARTS:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
}