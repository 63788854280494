import React, { Component } from "react";
import { BackTop, Spin, Icon } from "antd";
import { connect } from "react-redux";
import { MenuTab } from "SharedComponents";
import { CompanyInfo } from "../ComapnyInfo";
import { UserInfo } from "../UserInfo";
import Layout from "antd/lib/layout";

import { signOut, checkToken } from "ReduxActions/authActions";

import "./HeaderLayout.scss";

const actions = { signOut, checkToken };

class HeaderLayout extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.checkToken();
  }

  render() {
    const { signOut, user, spinning, tip } = this.props;
    const tabs = [
      // { key: "dashboard", route: "", label: "Dashboard", icon: "gold" },
      { key: "investments", route: "portfolio", label: "My Portfolio", icon: "appstore" },
      { key: "diversification", route: "diversification", label: "Diversification", icon: "pie-chart" },
      { key: "exited", route: "exited", label: "Exited Portfolio ", icon: "right-square" },
    ];
    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
    return (
      <div className="HeaderLayout">
        <Spin tip={tip} indicator={antIcon} spinning={spinning} >
          <Layout>
            <Layout.Header className="header-app">
              <CompanyInfo company={user.company} />
              <MenuTab
                root="/"
                tabs={tabs}
                handleClick={this.handleClick}
                withIcons={true}
              />
              <UserInfo
                signOut={signOut}
                profile={user.invProfile}
              />
            </Layout.Header>
            <Layout.Content style={{ padding: "0 50px", marginTop: 64, background: "#f9f9f9" }} >
              <div style={{ paddingTop: 24, minHeight: 400 }}>
                {this.props.children}
              </div>
            </Layout.Content>
          </Layout>
          <BackTop />
        </Spin>
      </div>
    );
  }
}

function mapStateToProps({ auth, utils }) {
  return {
    user: auth.user,
    spinning: utils.spinning,
    tip: utils.tip,
  };
}

export default connect(mapStateToProps, actions)(HeaderLayout);
