import React from "react";
import { CustomAvatar } from "SharedComponents";
import pentugramLogo from "Assets/images/Gold@3x.svg";

export const CompanyInfo = props => (
  <div className="CompanyInfo">
    {props.company && props.company.logo ? (
      <CustomAvatar shape="square" size={48} src={props.company.logo} />
    ) : (
      <img src={`${pentugramLogo}`} alt="Pentugram" height="40" width="40" />
    )}
    <span className="name-details ml-2">Investor Portal</span>
  </div>
);
