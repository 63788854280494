import React, { useEffect, useState } from "react";

import { Modal, Icon, Spin, message } from "antd";
import domtoimage from "Utils/domtoimage.js";
import jsPDF from "jspdf";
import { Container, CustomButton } from "SharedComponents";

import { CurvedPieChart, SimplePieChart } from "../../../diversification/DiversificationContext/PieChart/CustomPieChart";
import { PortfolioPanel } from "../../PortfolioTable/PortfolioPanel";
import { CoverPage, Page, EndPage } from "./Pages";

import "./PdfGenerator.scss";
import chunk from "lodash/chunk";
import sortBy from "lodash/sortBy";

const ITEMS_PER_PAGE = 4;

export function PdfGenerator(props) {
  const [ spin, setSpin ] = useState(false);
  const [ tip, setTip ] = useState("");

  useEffect(() => {
    props.onInit();
  }, []);

  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

  const updateIndicator = ({ tip, spin }) => {
    setTip(tip);
    setSpin(spin);
  }

  const parseData = (data) => {
    return data.map((it) => ({ name: it.name, value: Number(it.value) }));
  }

  const RecursiveDownLoad = (pdf, page) => {
    const node = document.getElementById(`generate_page_${page}`);

    domtoimage
      .toPng(node)
      .then(dataUrl => {
        pdf.addImage(dataUrl, "PNG", 0, 0);
        if ((pdfDistribution.length + 4) == page) {
          updateIndicator({ spin: false, tip: "" });
          message.success('Pdf downloaded successfully');
          pdf.save(`${props.me && props.me.invProfile ? props.me.invProfile.fullname : "statement-of-account" }.pdf`);
        } else {
          pdf.addPage();
          RecursiveDownLoad(pdf, page + 1);
        }
      })
      .catch(e => {
        updateIndicator({ spin: false, tip: "" });
        message.error('Download was unabled to complete');
      });
  }

  const downLoadAsPDF = () => {
    updateIndicator({ spin: true, tip: "Downloading..." });
    const pdf = new jsPDF("p", "mm", "a4", true);
    RecursiveDownLoad(pdf, 1);
  }

  const RecursivePrint = (print, page) => {
    const node = document.getElementById(`generate_page_${page}`);

    domtoimage
      .toPng(node)
      .then(dataUrl => {
        print.addImage(dataUrl, "PNG", 0, 0);
        if ((pdfDistribution.length + 4) == page) {
          updateIndicator({ spin: false, tip: "" });
          print.autoPrint();
          window.open(print.output('bloburl'), '_blank')
        } else {
          print.addPage();
          RecursivePrint(print, page + 1);
        }
      })
      .catch(e => {
        updateIndicator({ spin: false, tip: "" });
        message.error('Something went wrong, try again');
      });
  }

  const printPdf = () => {
    updateIndicator({ spin: true, tip: "Please wait..." });
    const print = new jsPDF("p", "mm", "a4", true);
    RecursivePrint(print, 1);
  }

  const splitDistribution = (dist) => {
    const mapped = dist.map(item => ({
      ...item,
      currency: item.currencyName && item.currencyName.code,
    }));
    const sortedArray = sortBy(mapped, ["frequency", "currency"]);
    const result = chunk(sortedArray, ITEMS_PER_PAGE).map(it => grouping(it));

    return result;
  };

  const grouping = (data) => {
    const groups = ["frequency", "currency"];
    const grouped = {};
    data.forEach(function (a) {
      groups.reduce(function (o, g, i) {
          o[a[g]] = o[a[g]] || (i + 1 === groups.length ? [] : {});
          return o[a[g]];
      }, grouped).push(a);
    });
    return grouped;
  }

  const pdfDistribution = splitDistribution(props.distribution);

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onClose}
      footer={null}
      closable={false}
      bodyStyle={{ minHeight: 1122, backgroundColor: "#dcdcdc" }}
      width="841"//"793"
    >
      <Spin indicator={antIcon} spinning={spin} tip={tip}>
      <div className="fixed-pos-gen-modal">
        <CustomButton
          tooltip="Download as PDF"
          className="mr-2"
          placement="bottom"
          icon="download"
          size="large"
          shape="circle"
          type="primary"
          onClick={downLoadAsPDF}
        />
        <CustomButton
          tooltip="Print"
          placement="bottom"
          icon="printer"
          size="large"
          shape="circle"
          type="primary"
          // disabled={true}
          onClick={printPdf}
        />
      </div>
      <div id="generate_page_1" className="PdfGenerator p-3">
        <CoverPage {...props.me} />
      </div>
      {
        pdfDistribution.map((page, idx) => (
          <div key={"page" + idx + 1} id={`generate_page_${idx + 2}`} className="PdfGenerator p-3">
            <Page {...props.me} title="">
              {
                Object.keys(page).map((frequency, index) => (
                  <div key={"currency" + index + idx + 1}>
                    {
                      Object.keys(page[frequency]).map((currency, x) => (
                        <PortfolioPanel
                          key={"item" + x + 1}
                          frequency={frequency}
                          currency={currency}
                          distribution={page[frequency][currency]}
                        />
                      ))
                    }
                  </div>
                ))
              }
            </Page>
          </div>
        ))
      }
      <div id={`generate_page_${pdfDistribution.length + 2}`} className="PdfGenerator p-3">
        <Page {...props.me} title="Portfolio Diversification">
          <div className="row justify-content-md-center">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <Container margin={16} padding={8}>
                <CurvedPieChart
                  mode="pdf"
                  loading={props.loading}
                  data={parseData(props.charts.rate)}
                  title="Investment Securities"
                  colors={props.colors}
                />
              </Container>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <Container margin={16} padding={8}>
                <SimplePieChart
                  mode="pdf"
                  loading={props.loading}
                  data={parseData(props.charts.country)}
                  title="Country Diversification"
                  colors={props.colors}
                />
              </Container>
            </div>
          </div>
        </Page>
      </div>
      <div id={`generate_page_${pdfDistribution.length + 3}`} className="PdfGenerator p-3">
        <Page {...props.me} title="Portfolio Diversification">
          <div className="row justify-content-md-center">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <Container margin={16} padding={8}>
                <SimplePieChart
                  mode="pdf"
                  loading={props.loading}
                  data={parseData(props.charts.currency)}
                  title="Currency Diversification"
                  colors={props.colors}
                />
              </Container>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <Container margin={16} padding={8}>
                <SimplePieChart
                  mode="pdf"
                  loading={props.loading}
                  data={parseData(props.charts.sector)}
                  title="Sub Sector Diversification"
                  colors={props.colors}
                />
              </Container>
            </div>
          </div>
        </Page>
      </div>
      <div id={`generate_page_${pdfDistribution.length + 4}`} className="PdfGenerator p-3">
        <EndPage {...props.me} />
      </div>
      </Spin>
    </Modal>
  );
}

