import React from "react";
import Avatar from "antd/lib/avatar";

export const CustomAvatar = props => (
    <Avatar
      size={props.size}
      src={props.src}
      icon={props.icon}
      shape={props.shape}
      style={props.style}
      alt={props.alt}
      srcSet={props.srcSet}
    >
      {props.children}
    </Avatar>
);

CustomAvatar.defaultProps = {
  size: 48,
  shape: "circle",
  srcSet: [],
};
