import React from "react";
import Empty from "antd/lib/empty";

export const EmptyData = props => (
  <Empty image={props.image} description={props.description} imageStyle={props.imageStyle} />
);

EmptyData.defaultprops = {
  image: Empty.PRESENTED_IMAGE_SIMPLE,
};
