import { SIGNING_IN, SIGNED_IN } from "ReduxActions/authActions";

const initialState = {
  authenticated: false,
  user: {},
  error: null,
  loading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SIGNING_IN:
      return {
        ...state,
        loading: action.payload,
      }

    case SIGNED_IN:
      return {
        ...state,
        authenticated: true,
        user: action.payload,
      };

    default:
      return state;
  }
}
