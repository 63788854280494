import { INDICATOR_SPINNER_UPDATED } from "ReduxActions/utilsActions";

const initialState = {
  spinning: false,
  tip: "",
  type: "loading",
};

export default function(state = initialState, action) {
  switch (action.type) {
    case INDICATOR_SPINNER_UPDATED:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state;
  }
}