
export const CHART_PALETTE_STORAGE_KEY = "diversification_chart_palette_value";
export const COLOR_NAMES = {
  VINTAGE: "vintage",
  ORANGE: "orange",
  MACRONS: "macarons",
  INFOGRAPHIC: "infographic",
  SHINE: "shine",
  ROME: "roma",
}

const vintageColors = ['#d87c7c', '#919e8b', '#d7ab82', '#6e7074', '#61a0a8', '#efa18d', '#787464', '#cc7e63', '#724e58', '#4b565b'];
const orangeColors = ['#f49f42', '#759aa0', '#e69d87', '#8dc1a9', '#ea7e53', '#eedd78', '#73a373', '#73b9bc', '#7289ab', '#91ca8c', '#dd6b66'];
const macaronsColors = ['#2ec7c9', '#b6a2de', '#5ab1ef', '#ffb980', '#d87a80', '#8d98b3', '#e5cf0d', '#97b552', '#95706d', '#dc69aa', '#07a2a4', '#9a7fd1', '#588dd5', '#f5994e', '#c05050', '#59678c', '#c9ab00', '#7eb00a', '#6f5553', '#c14089'];
const infographicColors = ['#C1232B', '#27727B', '#FCCE10', '#E87C25', '#B5C334', '#FE8463', '#9BCA63', '#FAD860', '#F3A43B', '#60C0DD'];
const shineColors = ['#2d4059', '#ea5455', '#decdc3', '#e5e5e5', '#3e978b', '#2ec1ac', '#cda819', '#8fc0a9'];
const romaColors = ['#E01F54', '#001852', '#f5e8c8', '#b8d2c7', '#c6b38e', '#a4d8c2', '#f3d999', '#d3758f', '#dcc392', '#2e4783', '#82b6e9', '#ff6347', '#a092f1', '#0a915d', '#eaf889', '#6699FF', '#ff6666', '#3cb371', '#d5b158', '#38b6b6'];

export function mapPaletteValueToPaletteName(paletteValue) {
  switch(paletteValue) {
    case COLOR_NAMES.VINTAGE:
      return "Palette 1";

    case COLOR_NAMES.ORANGE:
      return "Palette 2";

    case COLOR_NAMES.MACRONS:
      return "Palette 3";

    case COLOR_NAMES.INFOGRAPHIC:
      return "Palette 4";

    case COLOR_NAMES.SHINE:
      return "Palette 5";

    case COLOR_NAMES.ROME:
      return "Palette 6";
    
    default: return"Palette 1";
  }
}

export function mapPaletteValueToPaletteColors(paletteValue) {
  switch(paletteValue) {
    case COLOR_NAMES.VINTAGE:
      return vintageColors;

    case COLOR_NAMES.ORANGE:
      return orangeColors;

    case COLOR_NAMES.MACRONS:
      return macaronsColors;

    case COLOR_NAMES.INFOGRAPHIC:
      return infographicColors;

    case COLOR_NAMES.SHINE:
      return shineColors;

    case COLOR_NAMES.ROME:
      return romaColors;
    
    default: return romaColors;
  }
}

export const COLOR_PALETTES_LIST = [
  COLOR_NAMES.VINTAGE,
  COLOR_NAMES.ORANGE,
  COLOR_NAMES.MACRONS,
  COLOR_NAMES.INFOGRAPHIC,
  COLOR_NAMES.SHINE,
  COLOR_NAMES.ROME,
];
