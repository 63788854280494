import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Container } from "SharedComponents";

import { loadCharts } from "ReduxActions/diversificationActions";
import { COLOR_NAMES, CHART_PALETTE_STORAGE_KEY, mapPaletteValueToPaletteColors } from "Enums/colors";
import { setToLocalstorage, getFromLocalstorage } from "Utils/localStorage";

import { SimplePieChart, CurvedPieChart } from "./PieChart/CustomPieChart";
import { PalettePicker } from "../PalettePicker";

import "./DiversificationContext.scss";

const useStateWithLocalStorage = (localStorageKey, defaultValue) => {
  const [value, setValue] = React.useState(getFromLocalstorage(localStorageKey));

  React.useEffect(() => {
    setToLocalstorage(localStorageKey, value || defaultValue);
  }, [value || defaultValue]);

  return [value || defaultValue, setValue];
};


function DiversificationContext(props) {
  const [color, setColor] = useStateWithLocalStorage(CHART_PALETTE_STORAGE_KEY, COLOR_NAMES.VINTAGE);

  useEffect(() => {
    props.loadCharts();
  }, []);

  const onChange = (value) => {
    setColor(value);
  }

  function parseData(data) {

    return data.map((it) => ({ name: it.name, value: Number(it.value) }));
  }

  const { charts, loading } = props;
  console.log(11,charts);
  return (
    <div className="DiversificationContext m-5">
      <PalettePicker
        color={color}
        onChange={onChange}
      />
      <div className="row justify-content-md-center">
        <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6">
          <Container margin={16} padding={32}>
            <CurvedPieChart
              loading={loading}
              data={parseData(charts.rate)}
              title="Investment Securities"
              colors={mapPaletteValueToPaletteColors(color)}
            />
          </Container>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6">
          <Container margin={16} padding={32}>
            <SimplePieChart
              loading={loading}
              data={parseData(charts.country)}
              title="Country Diversification"
              colors={mapPaletteValueToPaletteColors(color)}
            />
          </Container>
        </div>
      </div>
      <div className="row justify-content-md-center">
        <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6">
          <Container margin={16} padding={32}>
            <SimplePieChart
              loading={loading}
              data={parseData(charts.currency)}
              title="Currency Diversification"
              colors={mapPaletteValueToPaletteColors(color)}
            />
          </Container>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6">
          <Container margin={16} padding={32}>
            <SimplePieChart
              loading={loading}
              data={parseData(charts.sector)}
              title="Sub Sector Diversification"
              colors={mapPaletteValueToPaletteColors(color)}
            />
          </Container>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ diversification }) {
  return {
    charts: diversification.charts,
    loading: diversification.loading,
  };
}

export default connect(mapStateToProps, { loadCharts })(DiversificationContext);
