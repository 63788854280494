import Skeleton from 'antd/lib/skeleton';
import Card from 'antd/lib/card';
import React from "react";

export const EmptyCard = props => (
  <div className="EmptyCard">
    <Card style={{ width: props.width, height: props.height }} loading={props.loading} />
  </div>
);

export const EmptySkeleton = props => (
  <div className="EmptySkeleton">
    <Card style={{ width: props.width, height: props.height }}>
      <Skeleton loading={props.loading} avatar active paragraph={{ rows: props.rows }} />
    </Card>
  </div>
);

EmptyCard.defaultProps = {
  width: "100%",
  loading: true,
};

EmptySkeleton.defaultProps = {
  loading: true,
  width: "100%",
  rows: 2,
};
