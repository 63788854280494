import React, { Component } from "react";
import { Link } from "react-router";
import Menu from "antd/lib/menu";
import Icon from "antd/lib/icon";


class MenuTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: window.location.pathname 
    };
  }

  handleClick = e => {
    this.setState({
      current: e.key
    });
  };

  render() {
    const { props } = this;
    return (
      <div className="MenuTab">
        <Menu onClick={this.handleClick} selectedKeys={[this.state.current]} mode="horizontal">
          {props.tabs.map(tab => {
            const itemFullRoute = props.root + tab.route;
            return (
              <Menu.Item key={itemFullRoute}>
                <Link to={itemFullRoute}>
                  {props.withIcons && <Icon type={tab.icon} theme={tab.theme} />}
                  {tab.label}
                </Link>
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    );
  }
}

export default MenuTab;
