import React, { Component } from "react";
import { connect } from "react-redux";

import { loadFiles } from "ReduxActions/dealActions";
import { OverlaySpinner, Container } from "SharedComponents";
import FilesManagement from "./FilesManagement";
import FilesPath from "./FilesPath";

import "./FilesContext.scss";

const actions = { loadFiles };
class FilesContext extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialPath: { icon: "home", path: "0" },
    };
  }

  componentDidMount() {
    this.props.loadFiles(this.props.params.id, this.state.initialPath);
  }

  onFileLoaded(folder) {
    this.props.loadFiles(this.props.params.id, folder);
  }

  render() {
    return (
      <div className="FilesContext">
        <OverlaySpinner isLoading={this.props.loading}>
          <Container padding={12}>
            <FilesPath
              path={this.props.path}
              files={this.props.files}
              loadFiles={folder => this.props.loadFiles(this.props.params.id, folder)}
            />
          </Container>
          <Container minHeight="400">
            <FilesManagement
              files={this.props.files}
              loadFiles={path => this.onFileLoaded(path)}
            />
          </Container>
        </OverlaySpinner>
      </div>
    );
  }
}

function mapStateToProps({ deal }) {
  return {
    files: deal.files,
    path: deal.filesPath,
    loading: deal.loadingItems,
  };
}

export default connect(mapStateToProps, actions)(FilesContext);
