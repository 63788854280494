import React, { useState } from "react";

import { Modal, Button } from "antd";
import { EmptyData, Spinner } from "SharedComponents";
import { AssetCard } from "../AssetCard";
import InvestmentsInfo from "../InvestmentsInfo";

export function PortfolioGrid({ loading, investments, openDeal}) {
  const [ showInvestments, setShowinvestments ] = useState(false);
  const [ deal, setDeal ] = useState(null);

  const getCurrency = (currency) => {
    return currency
      ? currency.symbol
        ? currency.symbol
        : currency.code
      : "--";
  }

  const closeInvestorInfo = () => {
    setShowinvestments(false);
    setDeal(null);
  }

  return (
    <div>
      {loading ? (
        <div className="flex-center">
          <Spinner isLoading={loading} />
        </div>
      ) : (
        <div>
          {investments.length ? (
            <div className="row">
              {investments.map(investment => (
                <div
                  key={investment.deal_id}
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-3 mb-4"
                >
                  <AssetCard
                    {...investment}
                    openDeal={openDeal}
                    showRedirect={true}
                    showInvestments={() => {
                      setDeal(investment);
                      setShowinvestments(true);
                    }}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="flex-center">
              <EmptyData description="No investments" />
            </div>
          )}
        </div>
      )}
      {showInvestments && (
        <Modal
          title={`My investments details in ${deal && deal.name}`}
          visible={showInvestments}
          onCancel={() => closeInvestorInfo()}
          bodyStyle={{ maxHeight: 600, overflow: "auto" }}
          closable={false}
          footer={[
            <Button key="back" onClick={() => closeInvestorInfo()}>
              Cancel
            </Button>
          ]}
        >
          <InvestmentsInfo
            dealId={deal && deal.deal_id}
            currency={deal ? getCurrency(deal.currencyName) : "--"}
          />
        </Modal>
        )}
    </div>
  );
}

