import React, { Component } from "react";
import { connect } from "react-redux";

import { loadDistribution, emptyItems } from "ReduxActions/dealActions";

import { StaticLabel } from "SharedComponents";
import { mapCurrencyToSymbol } from "Enums/currencies.js";
import DistributionTable from "./DistributionTable";

import "./DistributionContext.scss";

class DistributionContext extends Component {
  componentDidMount() {
    this.props.loadDistribution(this.props.params.id, 1, 10);
  }

  componentWillUnmount() {
    this.props.emptyItems("distribution");
  }

  get returnRateTotal() {
    const editing = this.props.editing;
    if(editing) return { total: editing.return_per_date || 0, currency: editing.currencyName ? editing.currencyName.code : "" };
    return { total: 0, currency: ""}
  }

  get frequency() {
    const editing = this.props.editing;
    if(editing) return editing.frequency;
    return "";
  }

  render() {
    return (
      <div className="distributioncontext">
        <StaticLabel
          styles={{ margin: 8 }}
          title="Distribution as on date"
          prefix={mapCurrencyToSymbol(this.returnRateTotal.currency)}
          value={this.returnRateTotal.total}
          precision={2}
        />
        <div>
          <DistributionTable
            loading={this.props.loading}
            distribution={this.props.distribution}
            meta={this.props.meta}
            loadDistribution={(page, size) => this.props.loadDistribution(this.props.params.id, page, size)}
            frequency={this.frequency}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps({ deal }) {
  return {
    distribution: deal.distribution,
    meta: deal.meta,
    loading: deal.loadingItems,
    editing: deal.editing,
  };
}

export default connect(mapStateToProps, { loadDistribution, emptyItems })(DistributionContext);
