import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as form } from "redux-form";

import { CLEAR_STORE } from "ReduxActions/authActions";

import auth from './authReducer';
import portfolio from './portfolioReducer';
import deal from './dealReducer';
import diversification from './diversificationReducer';
import utils from './utilsReducer';

const appReducer = combineReducers({
  auth,
  deal,
  portfolio,
  diversification,
  routing,
  utils,
  form,
});

const rootReducer = (state, action) => {
  if (action.type === CLEAR_STORE) {
    state = {};
  }

  return appReducer(state, action);
};

export default rootReducer;
