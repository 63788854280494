import React from "react";
import { EmptyData } from "SharedComponents";
import Folder from "./Folder";

const FilesManagement = props => {
  if (!props.files.length) return <EmptyData description="No files" />;
  return (
    <div className="flex-start-wrap">
      {props.files.map(file => (
        <Folder
          openFile={() => window.open(file.url)}
          openFolder={() => props.loadFiles({breadcrumbName: file.title, path: `${file.file_id}`})}
          key={file.file_id}
          {...file}
        />
      ))}
    </div>
  );
};

export default FilesManagement;
