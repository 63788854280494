import React from "react";
import Button from "antd/lib/button";
import Tooltip from "antd/lib/tooltip";

export const CustomButton = props => (
  <Tooltip placement={props.placement} title={props.tooltip}>
    <Button
      icon={props.icon}
      loading={props.isLoading}
      className={props.className}
      size={props.size}
      type={props.type}
      block={props.block}
      htmlType={props.htmlType}
      onClick={props.onClick}
      shape={props.shape}
      disabled={props.disabled}
    >
      {props.label}
    </Button>
  </Tooltip>
);

CustomButton.defaultProps = {
  size: "small",
  type: "default",
  htmlType: "button",
  placement: "top",
  disabled: false,
};
