import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { Spinner, CustomButton, GroupButton } from "SharedComponents";
import { loadCharts } from "ReduxActions/diversificationActions";
import { loadInvestments, loadInvestmentsDistribution } from "ReduxActions/portfolioActions";
import { updateIndicator } from "ReduxActions/utilsActions";
import { PortfolioGrid } from "../PortfolioGrid";
import { PortfolioTable } from "../PortfolioTable";
import { PdfGenerator } from "./PdfGenerator";
import { COLOR_NAMES, CHART_PALETTE_STORAGE_KEY, mapPaletteValueToPaletteColors } from "Enums/colors";
import { getFromLocalstorage } from "Utils/localStorage";

import "./PortfolioContext.scss";

const actions = { loadInvestments, loadInvestmentsDistribution, updateIndicator, loadCharts };
  const views = [
    { value: 1, icon: "appstore", theme:"filled" },
    { value: 2, icon: "unordered-list" },
  ]
class PortfolioContext extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 1,
      showPdf: false,
    };
  }

  componentDidMount() {
    this.props.loadInvestments();
  }

  get defaultKeys() {
    let currency = [];
    const distribution = this.props.distribution;
    const period = Object.keys(distribution);
    period.forEach(element => {
      currency.push(...Object.keys(distribution[element]))
    });

    return { period, currency };
  }

  renderView() {
    switch (this.state.view) {
      case 1:
        return (
          <PortfolioGrid
            investments={this.props.investments}
            loading={this.props.loading}
            openDeal={deal_id => this.props.router.push(`portfolio/${deal_id}`)}
            exited={false}
          />
        );
      case 2:
        return (
          <PortfolioTable
            loadInvestments={this.props.loadInvestmentsDistribution}
            distribution={this.props.distribution}
            defaultKeys={this.defaultKeys}
            loading={this.props.loading}
            openDeal={deal_id => this.props.router.push(`portfolio/${deal_id}`)}
          />
        );
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="PortfolioContext">
        <div className="flex-end mb-2">
          <div className="mr-2">
            <CustomButton
              placement="bottom"
              label="Export"
              size="default"
              onClick={() => this.setState({ showPdf: true })}
              // icon="vertical-align-bottom"
              // tooltip="Export as PDF"
              // onClick={() => this.props.updateIndicator({ spinning: true, tip: "Downloading..." })}
            />
          </div>
          <GroupButton
            options={views}
            defaultValue={this.state.view}
            buttonStyle="outline"
            handlechange={(view) => this.setState({ view })}
          />
        </div>
        {this.renderView()}
        {
          this.state.showPdf && (
            <PdfGenerator
              visible={this.state.showPdf}
              me={this.props.me}
              charts={this.props.charts}
              loading={this.props.loading}
              onInit={this.props.loadCharts}
              onClose={() => this.setState({ showPdf: false })}
              distribution={this.props.investments}
              defaultKeys={this.defaultKeys}
              colors={mapPaletteValueToPaletteColors(getFromLocalstorage(CHART_PALETTE_STORAGE_KEY) || COLOR_NAMES.VINTAGE)}
            />
          )
        }
      </div>
    );
  }
}

function mapStateToProps({ diversification, auth, portfolio }) {
  return {
    investments: portfolio.all,
    loading: portfolio.loading,
    distribution: portfolio.distribution,
    me: auth.user,
    charts: diversification.charts,
  };
}

export default connect(mapStateToProps, actions)(withRouter(PortfolioContext));
