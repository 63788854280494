import React from "react";
import { Table, Card, Row, Col } from "antd";
import { formatDecimalNumber, numberRoundFormatter } from "Utils/formatNumber";

import "./PortfolioTable.scss";

export function PortfolioPanel({ distribution, frequency, currency }) {

  const columns = (period) => [
    {
      title: "Entity",
      width:150,
      dataIndex: "name",
      render: (text, it) => <span style={{ fontWeight: 600 }}>{text} {it.svp_name && `( ${it.svp_name} )`}</span>,
    },
    {
      title: "Amount Invested",
      width:150,
      dataIndex: "investment_amount",
      render: (text, it) =>text? <span>{numberRoundFormatter(text, it.currency)}</span>:'',
    },
    {
      title: "Current Yield (Last Distribution)",
      width:150,
      dataIndex: "return_rate",
      render: (text, it) => text?<span>{formatDecimalNumber(text)} %</span>:'',
    },
    {
      title: `${period} distributions`,
      width:150,
      dataIndex: "last_month_distribution",
      render: (text, it) => <span>{numberRoundFormatter(text, it.currency)}</span>,
    },
    {
      title: "Cumulative distribution",
      width:150,
      dataIndex: "return_per_date",
      render: (text, it) => <span>{numberRoundFormatter(text, it.currency)}</span>,
    },
  ];
  function renderFooter(data, currency) {
    return  [{
      "rate": '',
      "currency": currency,
      "last_month_distribution": data.reduce((sum, record) => sum + record.last_month_distribution, 0),
      "return_per_date": data.reduce((sum, record) => sum + record.return_per_date, 0),
      "name": "Total",
      "investment_amount": data.reduce((sum, record) => sum + record.investment_amount, 0),
    }]
  }
  return (
    <div className="PortfolioTable" >
      <Card
        style={{}}
        type="inner"
        title={<div className="period-block">All amounts in {currency} - ( {frequency} Distribution )</div>}
        className='mb-4'
      >
        <Table
          pagination={false}
          bordered={true}
          columns={columns(frequency)}
          rowKey={(record, key) => key}
          dataSource={distribution}
          rowClassName="table-row-style"
          footer={(data) => (
            <Table
              pagination={false}
              columns={columns(frequency)}
              rowKey={(record, key) => key}
              dataSource={renderFooter(data, currency)}
              rowClassName="table-row-style"
              showHeader={false}
              style={{padding:"0"}}
            />
          )}
        />
      </Card>
    </div>
  );
}
